import React from "react"
import Layout from "../components/layout"
import Helmet  from "react-helmet"

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>404 | 猫背で進め</title>
        <meta name="description" content="猫背で進めは @alea12 のブログサイトです。"></meta>
      </Helmet>
      <div>
        404
      </div>
    </Layout>
  )
}
